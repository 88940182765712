<!--
 * @Author: Will
 * @Date: 2022-01-12 18:03:36
 * @LastEditors: Will
 * @LastEditTime: 2022-02-15 10:47:32
-->
<template>
  <div class="main">
    <div id="loading">
      <div id="loading-center">
        <div id="loading-center-absolute">
          <div class="object" id="object_one"></div>
          <div class="object" id="object_two"></div>
          <div class="object" id="object_three"></div>
          <div class="object" id="object_four"></div>
          <div class="object" id="object_five"></div>
          <div class="object" id="object_six"></div>
          <div class="object" id="object_seven"></div>
          <div class="object" id="object_eight"></div>
          <div class="object" id="object_big"></div>
        </div>
      </div>
    </div>
    <div class="txt">
      <div class="p">{{ $t('正在验证您的身份，请稍等') }}</div>
    </div>
  </div>
</template>
<script>
import { reactive, onMounted, computed, createVNode } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import { weixinPCLogin, weixinH5Login } from "@/api/user";
import { successCallback } from "@/utils/login.js";
import { Modal } from "ant-design-vue";
import { ExclamationCircleOutlined } from "@ant-design/icons-vue";
export default {
  setup() {
    const route = useRoute();
    const store = useStore();
    const state = reactive({
      code: route.query.auth_code || route.query.code,
      redirect: decodeURIComponent(route.query.redirect || "/"),
    });
    const platformConfig = computed(() => store.getters.platformConfig);

    onMounted(() => {
      fnLogin();
    });

    const fnLogin = () => {
      //通过code获取用户的基本信息
      weixinH5Login({ code: state.code, site: location.host }).then((res) => {
        if (res.ret == 0) {
          if (res.data.openID){
            // 未未绑定账号 去h5绑定
            return Modal.confirm({
              title: () => "服务号暂未绑定账号",
              icon: () => createVNode(ExclamationCircleOutlined),
              centered: true,
              cancelText: () => createVNode(""),
              onOk() {
                location.replace(`${location.protocol}//${platformConfig.value.mobileDomain}/view/account?openId=${res.data.openID}`);
              },
            });
          }
          successCallback(res, state);
          store.dispatch("setPlatform", "wx");
        }
      });
    };

    return {};
  },
};
</script>

<style lang="less" scoped>
@import "../../assets/style/login.less";
</style>
